export function fixPermission(id) {
    switch (id) {
        case 35:
            // lesson schedule view teacher
            return 21;
        case 38:
            // view of team information
            return 3;
        case 37:
            // lesson schedule view student
            return 23;
        case 36:
            // lesson schedule view parent
            return 22;
        case 23:
            // create courses type
            return 12;
        case 22:
            // manage_lessons_scheduling_and_assign_students_to_classes
            return 11;
        case 31:
            // have access to contact us log
            return 18;
        default:
            return id;
    }
}
